import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Grid, IconButton, Tooltip } from '@mui/material';
import Button from '../common/button';
import Chip from '../common/chip';
import { Card, BioText, DetailText, ExpertName } from './experts.style';
import { displayName, calculateAge } from '../../utils/profile';
import { stringToColor } from '../../utils/stringHelpers';
import Profile from '../profile';

// import StarIcon
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { useAuth } from '../../hooks';
import { updateUser } from '../../clients/profile';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

function ExpertRow({ profile, xs, sm, md, lg, xl }) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const { user, token } = useAuth();

  const [openProfile, setOpenProfile] = useState(false);

  const [isStarred, setIsStarred] = useState(
    user?.profile?.favorite_resources?.includes(profile.id) || false,
  );

  const rolesArray = profile?.one_liner || profile.roles?.split(',');

  const toggleStar = async (id) => {
    // if it exists then do this, if not, then set it to an empty array
    let updatedFavoriteResources = user?.profile.favorite_resources
      ? [...user?.profile.favorite_resources]
      : [];

    // check to see if the resource is favorited
    let found = updatedFavoriteResources?.includes(id);

    // if the resource is favorited, remove it; else, add it
    if (found) {
      updatedFavoriteResources = updatedFavoriteResources.filter(
        (resourceId) => resourceId !== id,
      );
    } else {
      updatedFavoriteResources.push(id);
    }

    setIsStarred(updatedFavoriteResources?.includes(id));

    await updateUser(dispatcher, navigate, user, token, {
      favorite_resources: updatedFavoriteResources,
    });
  };

  useEffect(() => {}, [isStarred]);

  return (
    <Grid item xs={12} sm={sm || 6} md={md || 4} lg={lg || 3} xl={xl || 3}>
      <Card>
        {user?.profile.profile_type === 'parent' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isStarred ? (
              <StarIcon
                sx={{
                  width: 30,
                  height: 30,
                }}
                color="primary"
                onClick={() => toggleStar(profile.id)}
              />
            ) : (
              <StarOutlineIcon
                sx={{
                  width: 30,
                  height: 30,
                  borderWidth: 2,
                }}
                color="primary"
                onClick={() => toggleStar(profile.id)}
              />
            )}
          </Box>
        )}
        <Box
          onClick={() => window.open(`/r/${profile.display_id}`, '_blank')}
          mb={1}
        >
          <Avatar
            alt={profile.first_name}
            src={profile.profile_image_url}
            sx={{
              width: 100,
              height: 100,
              margin: '0 auto',
              bgcolor: profile.profile_image_url
                ? '#FFFFFF'
                : stringToColor(profile.business_name || profile.first_name),
            }}
          >
            {!profile.profile_image_url
              ? profile.business_name?.charAt(0) ||
                profile.first_name?.charAt(0)
              : null}
          </Avatar>
          <Box mt={1}>
            <ExpertName>
              {profile.business_name ||
                displayName(profile.first_name, profile.last_name)}
            </ExpertName>
          </Box>
        </Box>
        {profile.one_liner && (
          <Box>
            <DetailText>{profile.one_liner}</DetailText>
          </Box>
        )}
        {!profile.one_liner && rolesArray && (
          <Box>
            <DetailText>{rolesArray.join(', ')}</DetailText>
          </Box>
        )}
        <Box>
          <BioText>{profile.bio}</BioText>
        </Box>
        <Box mt={2}>
          <Button shade="secondary">View</Button>
        </Box>
      </Card>
      {openProfile && (
        <Profile
          open={openProfile}
          profileId={profile.display_id}
          onClose={() => setOpenProfile(false)}
        />
      )}
    </Grid>
  );
}

ExpertRow.propTypes = {
  profile: PropTypes.any,
  onClick: PropTypes.func,
};

export default ExpertRow;
