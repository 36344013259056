import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Avatar, Box } from '@mui/material';

import Chip from '../../components/common/chip';
import ResourceThumbnail from '../../components/resource/resource-thumbnail';
import { buildSearchQuery } from '../../utils/search';

import {
  SET_PROFILE,
  ADD_EXPERT_PROFILE_ID,
  RESET_EXPERT_PROFILE_IDS,
} from '../../store/actions';

import { get, post } from '../../services/api.services';
import { API } from '../../constants';

import DBKMiniProfile from '../../components/session/digital-bonus-kit-mini-profile';
import example1 from './pdfs/example1.pdf';

export default function ResourceCard({
  resource,
  selected,
  onSelect,
  onOpen,
  selectMode = false,
  share,
}) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const filterSet = useSelector((state) => state.search.filters);
  const expertList = useSelector((state) => state.profiles.expertProfileIds);
  const profileMap = useSelector((state) => state.profiles.profilesById);
  const expertProfiles = expertList.map((expertId) => profileMap[expertId]);

  const offset = useSelector((state) => state.search.offset);
  const limit = useSelector((state) => state.search.limit);

  const fetchExperts = async () => {
    setLoading(true);

    const result = await get(
      `${API.PROFILE}${buildSearchQuery({ ...filterSet, offset, limit })}`,
      token,
    );

    if (offset === 0) {
      dispatcher({
        type: RESET_EXPERT_PROFILE_IDS,
        payload: {},
      });
    }

    if (result.status === 403) {
      navigate('/login/parents');
    } else if (result.status === 200) {
      result.data.experts.forEach((expert) => {
        dispatcher({
          type: SET_PROFILE,
          payload: {
            profile: expert,
          },
        });

        dispatcher({
          type: ADD_EXPERT_PROFILE_ID,
          payload: {
            expert,
          },
        });
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <Box
      borderRadius={2}
      p={2}
      textAlign="center"
      sx={{
        cursor: 'pointer',
        background: selectMode && share ? '#D6D6D6' : '#FFFFFF',
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '20px',
        border: selected
          ? '3px solid #5371ff'
          : `3px solid ${selectMode && share ? '#D6D6D6' : '#FFFFFF'}`,
        boxShadow:
          selectMode && !selected && !share
            ? '0px 0px 10px rgba(0, 0, 0, 0.5)'
            : 'none',
        position: 'relative',
      }}
      onClick={selectMode && !share ? onSelect : onOpen}
    >
      <>
        {selectMode && share && (
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <Chip
              color="white"
              label={`Sent on ${moment(share.shared_at).format('M/D/YYYY')}`}
            />
          </Box>
        )}
        <Box height={200}>
          <ResourceThumbnail url={resource.file_url} height="100%" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <Box mt={2}>{resource.title}</Box>
          <Box
            sx={{
              fontSize: '18px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '12px',
            }}
          >
            {expertProfiles.map((profile) => {
              if (resource.uploaded_by === profile.id) {
                return <DBKMiniProfile profile={profile} resource={resource} />;
              }
            })}
          </Box>
        </Box>
      </>
    </Box>
  );
}
